import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import UserForm, { FormValues, validate } from '@asaprint/asap/components/forms/UserForm.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { ADMIN_NEW_USER_CREATED_MESSAGE, ADMIN_NEW_USER_TITLE, BUTTON_CREATE } from '@asaprint/asap/locales/client.js';
import { ADMIN_USERS_ROUTE, DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import { NewUser_Load, NewUser_Save } from '@asaprint/asap/routes/__authenticated/admin/users/create.graphql';
import {
  NewUser_LoadQuery,
  NewUser_LoadQueryVariables,
  NewUser_SaveMutation,
  NewUser_SaveMutationVariables,
} from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import { displayName } from '@asaprint/common/helpers/User.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import Form, { OnSubmit } from '@engined/client/components/forms/Form.js';
import Loading from '@engined/client/components/Loading.js';
import { ConnectedSubmitButtons } from '@engined/client/components/SubmitButtons.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const logger = getLogger('@asap/routes/admin/users/create');

interface OwnProps {}

type Props = OwnProps;

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  username: '',
  password: '',
  passwordConfirmation: '',
  active: true,
  role: null,
  moneyUser: null,
  color: '#000000',
};

const Page: React.FunctionComponent<Props> = () => {
  const navigate = useNavigate();
  const { t } = useLocale();
  const { loading, data, error } = useQuery<NewUser_LoadQuery, NewUser_LoadQueryVariables>(NewUser_Load);
  const [userCreateExecute] = useMutation<NewUser_SaveMutation, NewUser_SaveMutationVariables>(NewUser_Save);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: OnSubmit<FormValues> = async ({ passwordConfirmation, color, role, moneyUser, ...values }) => {
    try {
      const response = await userCreateExecute({
        variables: {
          input: {
            ...values,
            role: role?.value ?? null,
            color: color.slice(1),
            moneyUserId: moneyUser?.value ?? null,
          },
        },
      });
      enqueueSnackbar(t(ADMIN_NEW_USER_CREATED_MESSAGE, { username: response.data.userSave.username }), {
        variant: 'success',
      });
      navigate(ADMIN_USERS_ROUTE, { state: { ignoreFormPrompt: true } });
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  };

  const moneyUsers = data?.moneyUsers?.edges;
  const moneyUserOptions = useMemo<Option[]>(
    () =>
      moneyUsers
        ? moneyUsers.map((e) => ({
            label: displayName(e.node),
            value: e.node.id,
          }))
        : [],
    [moneyUsers],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faUsers} /> {t(ADMIN_NEW_USER_TITLE)}
          </>
        }
      />

      <PagePaper>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <Form defaultValues={initialValues} onSubmit={onSubmit} validate={validate}>
            <UserForm passwordRequired moneyUserOptions={moneyUserOptions} />
            <ConnectedSubmitButtons submitLabel={t(BUTTON_CREATE)} backTo={ADMIN_USERS_ROUTE} />
          </Form>
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.UsersManage)(Page);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  const result = await apollo.query<NewUser_LoadQuery, NewUser_LoadQueryVariables>({ query: NewUser_Load });
  return result.data;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Používatelia',
      to: ADMIN_USERS_ROUTE,
    },
    {
      text: 'Nový používateľ',
    },
  ],
  meta: ({ locale: { t }, meta }: MetaFunctionArgs) => ({
    title: `Nový používateľ | Používatelia | Administrácia | ${meta.title}`,
  }),
};
